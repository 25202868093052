import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function DappHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="Automated-Market-Maker-DEX-Development-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: "url(assets/img/dapp-development.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Revolutionize Your Business with Decentralized Applications
                    (DApps)
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white"> </p>
                </div>
              </div>
              <div className="btn-box pt-5">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
