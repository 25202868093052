import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./Componet/Home/Index";
import AboutUs from "./Componet/About-Us/AboutUs";
import Blogs from "./Componet/Blogs/Blogs";
import Exchange from "./Componet/Exchange/Exchange";
import WhiteLabelExchange from "./Componet/White-Label-Exchange/WhiteLabelExchange";
import MarginTradingExchange from "./Componet/Margin-Trading-Exchange/MarginTradingExchange";
import SecurityExchange from "./Security-Exchange/SecurityExchange";
import P2PExchange from "./Componet/P2P-Exchange/P2PExchange";
import DerivativesExchange from "./Componet/Derivatives-Exchange/DerivativesExchange";
import DecentralizedExchange from "./Componet/Decentralized-Exchange/DecentralizedExchange";
import ArbitrageTradingBots from "./Componet/Arbitrage Trading Bots/ArbitrageTradingBots";
import MarketMakingServices from "./Componet/Market-Making-Services/MarketMakingServices";
import CryptocurrencyWallet from "./Componet/Cryptocurrency-Wallet/CryptocurrencyWallet";
import Web3Wallet from "./Componet/Web3 Wallet/Web3Wallet";
import MultiCurrencyWallet from "./Componet/MultiCurrency-Wallet/MultiCurrencyWallet";
import NFTWallet from "./Componet/NFT-Wallet/NFTWallet";
import TronWallet from "./Componet/Tron-Wallet/TronWallet";
import BitcoinWallet from "./Componet/Bitcoin-Wallet/BitcoinWallet";
import MobileWalletApp from "./Componet/Mobile-Wallet-App/MobileWalletApp";
import DefiWallet from "./Componet/Defi-Wallet/DefiWallet";
import WhiteLabelWallet from "./Componet/White-Label-Wallet/WhiteLabelWallet";
import DefiDevelopment from "./Componet/Defi-Development/DefiDevelopment";
import DefiStaking from "./Componet/Defi-Staking/DefiStaking";
import DefiLottery from "./Componet/Defi-Lottery/DefiLottery";
import DefiLendingAndBorrowing from "./Componet/Defi-Lending-And-Borrowing/DefiLendingAndBorrowing";
import DefiYienldFarming from "./Componet/Defi-Yienld-Farming/DefiYienldFarming";
import WebDevlopment from "./Componet/Web-3-0-Devlopment/WebDevlopment";
import Web3GameDevlopment from "./Componet/Web3-Game-Devlopment/Web3GameDevlopment";
import DexAggregator from "./Componet/Dex-Aggregator/DexAggregator";
import AMMDex from "./Componet/AMM-Dex/AMMDex";
import OrderBookDexs from "./Componet/Order-Book-Dexs/OrderBookDexs";
import CryptoBanking from "./Componet/Crypto-Banking/CryptoBanking";
import PaymentGateway from "./Componet/Payment-Gateway/PaymentGateway";
import CoinDevelopment from "./Componet/Coin-Development/CoinDevelopment";
import IDPDevelopment from "./Componet/IDP-Development/IDPDevelopment";
import CryptoLaunchpad from "./Componet/Crypto-Launchpad/CryptoLaunchpad";
import ICOdevelopment from "./Componet/ICO-Development/ICOdevelopment";
import NFT from "./Componet/NFT/NFT";
import WhiteLabelNFT from "./Componet/White-Label-NFT/WhiteLabelNFT";
import NFTGaming from "./Componet/NFT-Gaming/NFTGaming";
import NFTMarketplace from "./Componet/NFT-Marketplace/NFTMarketplace";
import NFTLoan from "./Componet/NFT-Loan/NFTLoan";
import SemiToken from "./Componet/Semi-Fungible-Token/SemiToken";
import NFTART from "./Componet/NFT-Art/NFTART";
import TradingBot from "./Componet/Trading-Bots/TradingBot";
import BlockchainDevelopment from "./Componet/Blockchain/Blockchain-Development/BlockchainDevelopment";
import HarshgraphDevelopment from "./Componet/Blockchain/Harshgraph-Development/HarshgraphDevelopment";
import BlockchainGameDevelopment from "./Componet/Blockchain/Blockchain-Game-Development/BlockchainGameDevelopment";
import SubStrate from "./Componet/Blockchain-Frameworks/SubStrate/SubStrate";
import Tezos from "./Componet/Blockchain-Frameworks/Tezos/Tezos";
import Cosmos from "./Componet/Blockchain-Frameworks/Cosmos/Cosmos";
import PolygonEdge from "./Componet/Blockchain-Frameworks/Polygon-Edge/PolygonEdge";
import PolygonBlockChain from "./Componet/Blockchain-Frameworks/PolygonBlockChain/PolygonBlockChain";
import CryptoGamingApp from "./Componet/CryptoGamingApp/CryptoGamingApp";
import CryptoGamingPlatform from "./Componet/CryptoGamingPlatform/CryptoGamingPlatform";
import MLM from "./Componet/MLM/MLM";
import ExchangeListing from "./Componet/Listing/Exchange-Listing/ExchangeListing";
import TokenListing from "./Componet/Listing/Token-listing/TokenListing";
import CMCListing from "./Componet/Listing/CMC-Listing/CMCListing";
import CustomLayer2 from "./Componet/Layer1-2Solution/CustomLayer2/CustomLayer2";
import EthereumSolution from "./Componet/Layer1-2Solution/Ethereum/EthereumSolution";
import Layer1Solution from "./Componet/Layer1-2Solution/Layer-1-Solution/Layer1olution";
import Layer2BlockchanNetwork from "./Componet/Layer1-2Solution/Layer2Blockchai-Network/Layer2BlockchanNetwork";
import Solana from "./Componet/Layer1-2Solution/Solana/Solana";
import CryptoMarketing from "./Componet/Marketing/Crypto-Token-Marketing/CryptoMarketing";
import SupplyChain from "./Componet/Enterprise-Blockchain/Supply-Chain/SupplyChain";
import TransportandLogistics from "./Componet/Enterprise-Blockchain/TransportAndLogistics.jsx/TransportandLogistics";
import SmartContractDev from "./Componet/Smart-Contract/SmartContractDev/SmartContractDev";
import SmartContractAudit from "./Componet/Smart-Contract/Smart-Contract-Audit/SmartContractAudit";
import AssetTokenization from "./Componet/Tokenization/Asset-Tokenization/AssetTokenization";
import CryptoAssetManagement from "./Componet/Tokenization/Crypto-Asset-Management/CryptoAssetManagement";
import ContactUsP from "./Componet/Coman/Contact-Us-P/ContactUsP";
import Dapp from "./Componet/Dapp/Dapp";
import Career from "./Componet/Career/Career";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about-us" element={<AboutUs />} />
        {/* <Route path="/blogs" element={<Blogs />} /> */}
        <Route path="/exchange" element={<Exchange />} />
        <Route path="/white-label-exchange" element={<WhiteLabelExchange />} />
        <Route
          path="/margin-trading-exchange"
          element={<MarginTradingExchange />}
        />
        <Route path="/security-exchange" element={<SecurityExchange />} />
        <Route path="/p2p-exchange" element={<P2PExchange />} />
        <Route
          path="/derivatives-exchange"
          element={<DecentralizedExchange />}
        />
        <Route
          path="/arbitrage-trading-bots"
          element={<ArbitrageTradingBots />}
        />
        <Route
          path="/market-making-services"
          element={<MarketMakingServices />}
        />

        <Route
          path="/cryptocurrency-wallet"
          element={<CryptocurrencyWallet />}
        />
        <Route path="/web3-wallet" element={<Web3Wallet />} />
        <Route path="/multicurrency-wallet" element={<MultiCurrencyWallet />} />
        <Route path="/nft-wallet" element={<NFTWallet />} />
        <Route path="/ethereum-wallet" element={<TronWallet />} />
        <Route path="/bitcoin-wallet" element={<BitcoinWallet />} />
        <Route path="/mobile-wallet-app" element={<MobileWalletApp />} />
        <Route path="/defi-wallet" element={<DefiWallet />} />
        <Route path="/white-label-wallet" element={<WhiteLabelWallet />} />
        <Route path="/nft-development" element={<NFT />} />
        <Route path="/nft-collection-launch" element={<WhiteLabelNFT />} />
        <Route path="/nft-marketplace" element={<NFTMarketplace />} />
        <Route path="/nft-loan" element={<NFTLoan />} />
        <Route path="/nft-gaming" element={<NFTGaming />} />
        <Route path="/defi-development" element={<DefiDevelopment />} />
        <Route path="/defi-staking" element={<DefiStaking />} />
        <Route path="/defi-lottery" element={<DefiLottery />} />
        <Route
          path="/defi-lending-and-borrowing"
          element={<DefiLendingAndBorrowing />}
        />
        <Route path="/defi-yield-farming" element={<DefiYienldFarming />} />
        <Route path="/web-30-devlopment" element={<WebDevlopment />} />
        <Route path="/web3-game-devlopment" element={<Web3GameDevlopment />} />
        <Route
          path="/decentralized-exchange"
          element={<DerivativesExchange />}
        />
        <Route path="/dex-aggregator" element={<DexAggregator />} />
        <Route path="/amm-dex" element={<AMMDex />} />
        <Route path="/order-book-dexs" element={<OrderBookDexs />} />
        <Route path="/crypto-banking" element={<CryptoBanking />} />
        <Route
          path="/cryptocurrency-payment-gateway"
          element={<PaymentGateway />}
        />
        <Route path="/token-development" element={<CoinDevelopment />} />
        <Route path="/ido-development" element={<IDPDevelopment />} />
        <Route path="/crypto-launchpad" element={<CryptoLaunchpad />} />
        <Route path="/ico-development" element={<ICOdevelopment />} />
        <Route path="/trading-bot" element={<TradingBot />} />
        <Route path="/crypto-gaming-app" element={<CryptoGamingApp />} />
        <Route path="/mlm" element={<MLM />} />

        <Route path="/exchange-listing" element={<ExchangeListing />} />
        <Route path="/token-listing" element={<TokenListing />} />
        <Route path="/coingecko-and-cmc-listing" element={<CMCListing />} />
        <Route path="/dapp-development" element={<Dapp />} />
        {/* <Route path="/website-listing" element={<WebsiteListing/>}/> */}

        <Route path="/marketing" element={<CryptoMarketing />} />
        <Route path="/contact-us" element={<ContactUsP />} />
        <Route path="/career" element={<Career />} />

        {/* blockchain */}
        <Route
          path="/blockchain-development"
          element={<BlockchainDevelopment />}
        />
        <Route
          path="/harshgraph-development"
          element={<HarshgraphDevelopment />}
        />
        <Route
          path="/blockchain-game-development"
          element={<BlockchainGameDevelopment />}
        />
        <Route path="/substrate" element={<SubStrate />} />
        <Route path="/tezos" element={<Tezos />} />
        <Route path="/cosmos" element={<Cosmos />} />
        <Route path="/polygon-edge" element={<PolygonEdge />} />
        <Route path="/polygon-blockchain" element={<PolygonBlockChain />} />

        <Route path="/bsc" element={<Layer1Solution />} />
        <Route path="/ethereum" element={<EthereumSolution />} />
        <Route path="/solana" element={<Solana />} />
        <Route path="/cardano" element={<CustomLayer2 />} />
        <Route path="/polkadot" element={<Layer2BlockchanNetwork />} />
        <Route path="/ton" element={<SupplyChain />} />
        <Route
          path="/enterprise-solution"
          element={<TransportandLogistics />}
        />
        <Route
          path="/smart-contract-development"
          element={<SmartContractDev />}
        />
        <Route path="/deFi-smart-contract" element={<NFTART />} />
        <Route path="/smart-contract-audit" element={<SmartContractAudit />} />
        <Route path="/asset-tokenization" element={<AssetTokenization />} />
        <Route
          path="/crypto-asset-management"
          element={<CryptoAssetManagement />}
        />
        <Route path="/securitization" element={<CryptoGamingPlatform />} />
        <Route path="/p2p-lending" element={<SemiToken />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
