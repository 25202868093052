import React from "react";

export default function Footar() {
  return (
    <>
      <footer className="main-footer pb-5">
        <div className="auto-container">
          <div className="pt-5">
            <div className="row clearfix">
              {/* <div className="lines">
                <div className="line" />
                <div className="line" />
                <div className="line" />
              </div> */}
              <div className="col footer-column">
                <div className="footer-widget links-widget">
                  <div className="widget-title">
                    <h3>Services</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix dots ">
                      <li>
                        <a href="/blockchain-development">
                          Blockchain Development
                        </a>
                      </li>
                      <li>
                        <a href="/exchange">Exchange Development</a>
                      </li>
                      <li>
                        <a href="/dapp-development">dApp Development</a>
                      </li>
                      <li>
                        <a href="/nft-development">NFT</a>
                      </li>
                      <li>
                        <a href="/token-development">Coin/Token Development</a>
                      </li>
                      <li>
                        <a href="/marketing">Marketing</a>
                      </li>
                      <li>
                        <a
                          href="https://calendly.com/xeer-technology"
                          target="_block"
                        >
                          Consulting
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col footer-column">
                <div className="footer-widget links-widget ml_60">
                  <div className="widget-title">
                    <h3>Company</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix dots">
                      <li>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/career">Career</a>
                      </li>
                      {/* <li>
                        <a href="/blogs">Blog</a>
                      </li> */}
                      <li>
                        <a
                          href="https://drive.google.com/file/d/1iqlu5hDjaOX_wm0p_L06lxSSbUfeUSsC/view?usp=sharing"
                          target="_blank"
                        >
                          Media Files
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col footer-column">
                <div className="footer-widget links-widget">
                  <div className="widget-title">
                    <h3>Hire Developer</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix dots">
                      <li>
                        <a href="/contact-us">Blockchain Dev</a>
                      </li>
                      <li>
                        <a href="/contact-us">Full Stack</a>
                      </li>
                      <li>
                        <a href="/contact-us">NodeJs</a>
                      </li>
                      <li>
                        <a href="/contact-us">React</a>
                      </li>
                      <li>
                        <a href="/contact-us">Solana </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col footer-column">
                <div className="footer-widget links-widget">
                  <div className="widget-title">
                    <h3>Smart Contract</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix dots">
                      <li>
                        <a href="/mlm">MLM Smart Contract </a>
                      </li>
                      <li>
                        <a href="/ethereum">Ethereum</a>
                      </li>
                      <li>
                        <a href="/polygon-blockchain">Polygon</a>
                      </li>
                      <li>
                        <a href="/solana">Solana Smart Contract </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 footer-column">
                <div className="footer-widget links-widget">
                  <div>
                    <a href="/">
                      <img
                        src="assets/img/xeer_white_logo.svg"
                        alt="xeer_white_logo"
                        style={{ height: "60px" }}
                      />
                    </a>
                    <p>All Things Decentralized</p>
                  </div>
                  <div className="widget-title">
                    {/* <h3>Contact Us</h3> */}
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li>
                        <a
                          href="mailto:sales@xeertechnology.com"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-envelope-at"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                            <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                          </svg>
                          &nbsp; sales@xeertechnology.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://join.skype.com/invite/NdLW2iWggw4kXeer Technologyhttps"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-skype"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                          </svg>
                          &nbsp;XEER Technology
                        </a>
                      </li>
                      <li>
                        <a href="https://wa.me/+917383847632" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-whatsapp"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                          </svg>
                          &nbsp; +91 7383847632
                        </a>
                      </li>
                    </ul>
                    <div className="py-5">
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                          <div className="d-flex align-items-end">
                            <img
                              style={{ width: "27px" }}
                              src="assets/img/gujarat.svg"
                              alt="gujarat"
                              className="img-fluid"
                            />
                            <p className="">Gujarat, India</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                          <div className="d-flex align-items-end">
                            <img
                              style={{ width: "35px" }}
                              src="assets/img/dubai.svg"
                              alt="dubai"
                              className="img-fluid"
                            />
                            <p className="">Dubai, UAE</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                  <div className="footer-widget logo-widget">
                    <ul className="social-links clearfix">
                      <li>
                        <a
                          href="https://www.facebook.com/people/Xeer-Technology/61555342661387/"
                          target="_blank"
                        >
                          <i className="fa-brands fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="https://x.com/technology_xeer" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-twitter-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/xeer-technology/"
                          target="_blank"
                        >
                          <i className="fa-brands fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/xeer.technology/"
                          target="_blank"
                        >
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/Xeertechnology" target="_blank">
                          <i className="fa-brands fa-telegram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="copyright">
                    <p className="text-end">
                      <a href="/">XEER Technology</a> © 2024 All Rights
                      Reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
