import React from "react";

import Header from "../Coman/Header";
import Hero from "./Hero";
import OurServices from "./OurServices";
import Industries from "./Industries";
import Footar from "../Coman/Footar";
import Blog from "./Blog";
import HowDoWork from "./HowDoWork";
import ContactUs from "../Coman/ContactUs";
import Integrations from "./Integrations";
import BlockchainOffer from "./BlockchainOffer";
import TechnologiesweWork from "./TechnologiesweWork";
import TechnologyOffers from "./TechnologyOffers";
import BlockchainSolutions from "./BlockchainSolutions";

export default function Index() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <Hero />
        <OurServices />
        <BlockchainOffer />
        <Industries />
        <TechnologyOffers />
        <Integrations />
        <BlockchainSolutions />
        <HowDoWork />
        <TechnologiesweWork />
        <Blog />
        <ContactUs />
        <Footar />
      </div>
    </>
  );
}
