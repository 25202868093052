import React from "react";

export default function BenefitsOfJoining() {
  return (
    <>
      <section className="feature-section py_100">
        <div className="auto-container">
          <div data-animation-box className="sec-title text-center mb_55">
            {/* <h2>Why Choose Xeer Technology?</h2> */}
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="pb-5 mb-4">
                <h1>Benefits of Joining Xeer Technology</h1>
              </div>
              <div className="content-box mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <p>
                        Competitive salary and performance-based incentives.
                      </p>
                    </li>
                    <li>
                      <p>Flexible working hours with a hybrid work model.</p>
                    </li>
                    <li>
                      <p>Health insurance and wellness programs.</p>
                    </li>
                    <li>
                      <p>
                        Opportunities to attend global tech conferences and
                        events.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ms-md-5">
                <div className="image-box ">
                  <img
                    src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                    alt="why_choose_xeer_technology_for_margin_trading"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
