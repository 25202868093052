import React from "react";

export default function StartedToday() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">Get Started Today</h2>
            <p className="text-white py-3">
              Transform your vision into reality with Xeer Technology. Contact
              us today to discuss your DApp requirements and embark on the
              journey toward decentralization.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
