import React from "react";

export default function Hyperledger() {
  return (
    <>
      <section className="feature-section py_100">
        <div className="auto-container">
          <div data-animation-box className="sec-title text-center mb_55">
            <h2>Why Choose Xeer Technology?</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ms-md-5 ps-md-5">
                <div className="image-box ">
                  <img
                    src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                    alt="why_choose_xeer_technology_for_margin_trading"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h5>Expert Team</h5>
                      <p>
                        Our blockchain experts bring years of experience in DApp
                        development.
                      </p>
                    </li>
                    <li>
                      <h5>End-to-End Services</h5>
                      <p>
                        From ideation to deployment, we handle every step of the
                        process.
                      </p>
                    </li>
                    <li>
                      <h5>Focus on Security:</h5>
                      <p>
                        We prioritize secure development practices to protect
                        your <br /> data and transactions.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
