import React from "react";

export default function WorkWithUs() {
  return (
    <>
      <section className="feature-section py_100">
        <div className="auto-container">
          <div data-animation-box className="sec-title text-center mb_55">
            <h2>Why Work With Us?</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ms-md-5 ps-md-5">
                <div className="image-box ">
                  <img
                    src="assets/img/why-work-with-us.png"
                    alt="why-work-with-us"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box pt-sm-5 mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h5>Innovative Projects</h5>
                      <p>
                        Work on groundbreaking blockchain and crypto projects
                        that redefine industries.
                      </p>
                    </li>
                    <li>
                      <h5>Collaborative Environment</h5>
                      <p>
                        Be part of a dynamic team that values creativity and
                        teamwork.
                      </p>
                    </li>
                    <li>
                      <h5>Professional Growth</h5>
                      <p>
                        We invest in your career growth through training,
                        mentorship, and skill development programs.
                      </p>
                    </li>
                    <li>
                      <h5>Global Impact</h5>
                      <p>
                        Contribute to solutions that influence businesses and
                        communities worldwide.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
