import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import CareerHero from "./CareerHero";
import WorkWithUs from "./WorkWithUs";
import CurrerOpportunities from "./CurrerOpportunities";
import BenefitsOfJoining from "./BenefitsOfJoining";
import Apply from "./Apply";

export default function Career() {
  return (
    <div class="boxed_wrapper ltr">
      <Header />
      <CareerHero />
      <WorkWithUs />
      <CurrerOpportunities />
      <BenefitsOfJoining />
      <Apply />
      <Footar />
    </div>
  );
}
