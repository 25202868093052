import React from "react";

export default function PowerofDapp() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our DApp Development Services</h2>
            <p className="pt-2">
              At Xeer Technology, we specialize in creating cutting-edge
              decentralized applications (DApps) that empower businesses to
              harness the potential of blockchain technology. Whether you’re
              looking to enhance transparency, boost efficiency, or provide
              secure solutions for your users, our DApp development services
              ensure your business stays ahead in the decentralized era.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/dapps.svg"
                      alt="custom_nft_designs"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Custom DApp Development</h3>
                  <ul className="list-style-mark clearfix">
                    <li>
                      <p className="text-start">
                        Tailored solutions to meet your unique business
                        requirements.
                      </p>
                    </li>
                    <li>
                      <p className="text-start">
                        Blockchain agnostic: We work with Ethereum, BSC, Solana,
                        Polygon, and more.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/one/smart_contract.svg"
                      alt="nft_contract_development"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Smart Contract Development</h3>
                  <ul className="list-style-mark clearfix">
                    <li>
                      <p className="text-start">
                        Reliable and secure smart contracts to automate
                        processes.
                      </p>
                    </li>
                    <li>
                      <p className="text-start">
                        Extensive testing to ensure zero vulnerabilities.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/Seamless_ui_ux_01.svg"
                      alt="marketplace_integration"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>DApp Design & UI/UX</h3>
                  <ul className="list-style-mark clearfix">
                    <li>
                      <p className="text-start">
                        User-friendly interfaces for seamless interactions.
                      </p>
                    </li>
                    <li>
                      <p className="text-start">
                        Responsive designs optimized for web and mobile.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/defi/defi-y-f/blockchain_infrastructure.svg"
                      alt="token_standards_compliance"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Enterprise Blockchain Solutions</h3>
                  <ul className="list-style-mark clearfix">
                    <li>
                      <p className="text-start">
                        Scalable DApps for businesses of all sizes.
                      </p>
                    </li>
                    <li>
                      <p className="text-start">
                        Integrate blockchain technology to enhance operational
                        efficiency.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/customer_support_chat.svg"
                      alt="nft_wallet_development"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Maintenance & Support</h3>
                  <ul className="list-style-mark clearfix">
                    <li>
                      <p className="text-start">
                        Continuous updates for compatibility and security.
                      </p>
                    </li>
                    <li>
                      <p className="text-start">
                        24/7 technical support to ensure uninterrupted
                        operations.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
