import React from "react";
import DappHero from "./DappHero";
import Hyperledger from "./Hyperledger";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import PowerofDapp from "./PowerofDapp";
import { Helmet } from "react-helmet";
import LookingD from "../About-Us/LookingD";
import StartedToday from "./StartedToday";

export default function Dapp() {
  return (
    <>
      <Helmet>
        <title>NFT Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Create unique, scalable, and secure NFT solutions with Xeer Technology. From token creation to marketplace integration, we’ve got you covered."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <DappHero />
        <Hyperledger />
        <StartedToday />
        <PowerofDapp />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
