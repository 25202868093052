import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function CareerHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="Automated-Market-Maker-DEX-Development-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/join-our-team-at-xeer-technology.jpg)",
        }}
      >
        <div className="auto-container mt-5 py-5">
          <div className="row align-items-center py_80">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Join Our Team at <br /> Xeer Technology
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white"> </p>
                </div>
              </div>
              <a href="/contact-us" className="theme-btn btn-one">
                Contact Us
              </a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
