import React from "react";

export default function Apply() {
  return (
    <>
      <section className="cta-section">
        <div className="bg-color" />
        <div className="auto-container">
          <div className="inner-container pb-5 pb-md-0">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-23.png)",
                }}
              />
              <div
                className="pattern-2 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-24.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-25.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-26.png)",
                }}
              />
            </div>
            <div className="inner-box">
              <div className="py-5">
                <h2>Apply Now</h2>
                <br />
                <p className="text-white">
                  Ready to make an impact? Explore our open positions or send
                  your resume to&nbsp;&nbsp;
                  <a
                    href="mailto:sales@xeertechnology.com"
                    className="text-white"
                    target="_blank"
                  >
                    info@xeertechnology.com.&nbsp;&nbsp;
                  </a>
                  Let’s build the future together!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
