import React from "react";
import Header from "../Header";
import Footar from "../Footar";
import ContactHero from "./ContactHero";

export default function ContactUsP() {
  return (
    <div>
      <Header />
      <ContactHero />
      <Footar />
    </div>
  );
}
